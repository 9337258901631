import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'app-product-footer',
    templateUrl: 'product-footer.component.html'
})

export class ProductFooterComponent{
    test: Date = new Date();
}
