<!-- Topbar -->
<nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <!-- Sidebar Toggle (Topbar) -->
    <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
    </button>
    <!-- Topbar Search -->
    <!-- <form class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
       <div class="input-group">
          <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2">
          <div class="input-group-append">
             <button class="btn btn-primary" type="button">
             <i class="fa fa-search fa-sm"></i>
             </button>
          </div>
       </div>
    </form> -->
    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto">
       <!-- Nav Item - Search Dropdown (Visible Only XS) -->
       <li class="nav-item dropdown no-arrow d-sm-none">
          <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-search fa-fw"></i>
          </a>
          <!-- Dropdown - Messages -->
          <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
             <form class="form-inline mr-auto w-100 navbar-search">
                <div class="input-group">
                   <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2">
                   <div class="input-group-append">
                      <button class="btn btn-primary" type="button">
                      <i class="fa fa-search fa-sm"></i>
                      </button>
                   </div>
                </div>
             </form>
          </div>
       </li>
       <!-- Nav Item - Alerts -->
       <li class="nav-item dropdown no-arrow mx-1">
          <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
             <i class="fa fa-bell fa-fw"></i>
             <!-- Counter - Alerts -->
             <span class="badge badge-danger badge-counter">3+</span>
          </a>
          <!-- Dropdown - Alerts -->
          <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
             <h6 class="dropdown-header">
                Notifications
             </h6>
             <a class="dropdown-item d-flex align-items-center" href="#">
                <div class="mr-3">
                   <div class="icon-circle bg-primary">
                      <i class="fa fa-files-o text-white"></i>
                   </div>
                </div>
                <div>
                   <div class="small text-gray-500">Sep 1, 2020</div>
                   <span class="font-weight-bold">This section is currently under development!</span>
                </div>
             </a>
             <a class="dropdown-item d-flex align-items-center" href="#">
                <div class="mr-3">
                   <div class="icon-circle bg-success">
                      <i class="fa fa-user text-white"></i>
                   </div>
                </div>
                <div>
                   <div class="small text-gray-500">Sep 2, 2020</div>
                   This section is currently under development!
                </div>
             </a>
             <a class="dropdown-item d-flex align-items-center" href="#">
                <div class="mr-3">
                   <div class="icon-circle bg-warning">
                      <i class="fa fa-user text-white"></i>
                   </div>
                </div>
                <div>
                   <div class="small text-gray-500">Sep 3, 2020</div>
                   This section is currently under development!
                </div>
             </a>
             <a class="dropdown-item text-center small text-gray-500" href="#">Please try later!</a>
          </div>
       </li>
       <!-- Nav Item - Messages -->
       <li class="nav-item dropdown no-arrow mx-1">
          <a class="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
             <i class="fa fa-envelope fa-fw"></i>
             <!-- Counter - Messages -->
             <span class="badge badge-danger badge-counter">7</span>
          </a>
          <!-- Dropdown - Messages -->
          <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
             <h6 class="dropdown-header">
                Message Center
             </h6>
             <a class="dropdown-item d-flex align-items-center" href="#">
                <div class="dropdown-list-image mr-3">
                   <img class="rounded-circle bg-light" src="../../../../../assets/img/SS_Logo_Small.svg" alt="">
                   <div class="status-indicator bg-success"></div>
                </div>
                <div class="font-weight-bold">
                   <div class="text-truncate">Hi there! I am still under maintainance.</div>
                   <div class="small text-gray-500">Frontend Developer · 18m</div>
                </div>
             </a>
             <a class="dropdown-item d-flex align-items-center" href="#">
                <div class="dropdown-list-image mr-3">
                   <img class="rounded-circle bg-light" src="../../../../../assets/img/SS_Logo_Small.svg" alt="">
                   <div class="status-indicator"></div>
                </div>
                <div>
                   <div class="text-truncate">Hi there! I am still under maintainance.</div>
                   <div class="small text-gray-500">Backend Developer · 1d</div>
                </div>
             </a>
             <a class="dropdown-item d-flex align-items-center" href="#">
                <div class="dropdown-list-image mr-3">
                   <img class="rounded-circle bg-light" src="../../../../../assets/img/SS_Logo_Small.svg" alt="">
                   <div class="status-indicator bg-warning"></div>
                </div>
                <div>
                   <div class="text-truncate">Hi! We will notify you later, keep up the good work!</div>
                   <div class="small text-gray-500">SamrtSewa Team · 4d</div>
                </div>
             </a>
             <a class="dropdown-item d-flex align-items-center" href="#">
                <div class="dropdown-list-image mr-3">
                   <img class="rounded-circle bg-light" src="../../../../../assets/img/SS_Logo_Small.svg" alt="">
                   <div class="status-indicator bg-success"></div>
                </div>
                <div>
                   <div class="text-truncate">Updating the messages...</div>
                   <div class="small text-gray-500">Development Team · 1w</div>
                </div>
             </a>
             <a class="dropdown-item text-center small text-gray-500" href="#">Please Try Later!</a>
          </div>
       </li>
       <div class="topbar-divider d-none d-sm-block"></div>
       <!-- Nav Item - User Information -->
       <li class="nav-item dropdown no-arrow">
          <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="mr-2 d-none d-lg-inline text-gray-600 small">SmartSewa Admin</span>
          <img class="img-profile rounded-circle bg-light" src="../../../../../assets/img/SS_Logo_Small.svg">
          </a>
          <!-- Dropdown - User Information -->
          <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
             <a class="dropdown-item" href="#">
             <i class="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
             Profile
             </a>
             <a class="dropdown-item" href="#">
             <i class="fa fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
             Settings
             </a>
             <a class="dropdown-item" href="#">
             <i class="fa fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
             Activity Log
             </a>
             <div class="dropdown-divider"></div>
             <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
             <i class="fa fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
             Logout
             </a>
          </div>
       </li>
    </ul>
 </nav>
 <!-- End of Topbar -->