import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { LoginComponent } from './login/login.component';
import { AppRoutes } from './app-routing';
import { AppComponent } from './app.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SelectModule } from 'ng-select';
import { TokenInterceptor } from 'src/app/core/interceptors/token.interceptor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {MatTableModule} from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
// import { ViewUserComponent } from './user/view-user/view-user.component';
// import { UserListingComponent } from './application/user/components/user-listing/user-listing.component';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        // ViewUserComponent,
        // UserListingComponent,


    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        NgxDatatableModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        SelectModule,
        MatTableModule,
        MatNativeDateModule,
        BsDatepickerModule.forRoot(),
        RouterModule.forRoot(AppRoutes, {
            useHash: false
        }),
        ToastrModule.forRoot()
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        }
    ],
    bootstrap: [AppComponent],
    exports: [
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class AppModule { }
