<div class="d-md-flex half">
<div class="bg" style="background-image: url('assets/img/bg.jpeg');"></div>
<div class="contents">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-12">
        <div class="form-block mx-auto" *ngIf="!showloginTypeForm">
          <div class="text-center mb-5">
            <img src="assets/img/app_logo.png" style="width: 40px;"/>
            <div class="row mt-3">
              <div class="col-md-3">
            <hr>
              </div>
              <div class="col-md-6">
            <h3 class="mt-2 text-color-secondary">Login to <strong class="text-color-primary">SmartSewa</strong></h3>
              </div>
              <div class="col-md-3">
                <hr>
              </div>
            </div>
          </div>
          <form [formGroup]="loginFormGroup" #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
            <div class="form-group first">
              <label for="username">Mobile/User Id</label>
              <input type="text" class="form-control" placeholder="mobile/userid" name="username" id="username" formControlName="username"  [ngClass]="{ 'is-invalid': submitted && loginFormGroup.controls.username.errors }">
              <div *ngIf="submitted && loginFormGroup.controls.username.errors" class="invalid-feedback">
                <div *ngIf="loginFormGroup.controls.username.errors.required">Mobile/User Id is required</div>
              </div>
            </div>
            <div class="form-group last mb-3">
              <label for="password">Password</label>
              <input type="password" class="form-control form-control-user" placeholder="Password" id="password" name="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && loginFormGroup.controls.password.errors }">
              <div *ngIf="submitted && loginFormGroup.controls.password.errors" class="invalid-feedback">
                <div *ngIf="loginFormGroup.controls.password.errors.required">Password is required</div>
              </div>
            </div>
            <div class="d-sm-flex mb-5 align-items-center">
              <label class="control control--checkbox mb-3 mb-sm-0"><span class="caption">Remember me</span>
                <input type="checkbox" checked="checked" />
                <div class="control__indicator"></div>
              </label>
              <span class="ml-auto"><a href="#" class="forgot-pass">Forgot Password</a></span>
            </div>
            <button type="submit" value="Log In" class="btn btn-block py-2 btn-primary">Log In</button>
          </form>
        </div>
        <div class="form-block mx-auto" *ngIf="!!showloginTypeForm">
          <div class="text-center mb-5">
            <img src="assets/img/app_logo.png" style="width: 40px;"/>
            <div class="row mt-3">
              <div class="col-md-3">
            <hr>
              </div>
              <div class="col-md-6">
            <h3 class="mt-2 text-color-secondary">Login to <strong class="text-color-primary">SmartSewa</strong></h3>
              </div>
              <div class="col-md-3">
                <hr>
              </div>
            </div>
          </div>
          <form class="form-signin" [formGroup]="loginTypeFormGroup" #loginTypeForm="ngForm" (ngSubmit)="onloginTypeSubmit(loginTypeForm)" *ngIf="loginTypeFormGroup">
            <div class="form-group" *ngIf="!!rolesDropdownData && rolesDropdownData.length > 0">
              <label for="adminrole">Login Type<span>*</span></label>
              <ng-select
                      [options]="rolesDropdownData"
                      bindValue="value"
                      bindLabel="label"
                      [multiple]="false"
                      [closeOnSelect]="false"
                      [searchable]="false"
                      placeholder="Select..."
                      clearAllText="Clear"
                      formControlName="roles"
                      name="roles"
                      id="adminrole"
                      class="form-control bg-light custom"
                      [ngClass]="{ 'is-invalid': typesubmitted && admintypeloginForm.roles.errors }"
              >
              </ng-select>
              <div *ngIf="typesubmitted && admintypeloginForm.roles.errors" class="invalid-feedback">
                <div *ngIf="admintypeloginForm.roles.errors.required">Admin Type is required</div>
              </div>
            </div>
            <button type="submit" class="btn btn-secondary btn-user btn-block mt-5">Confirm</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</div>