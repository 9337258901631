import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import {StorageService} from "../../core/services/storage.service";
declare var $:any;

@Component({
  selector: 'app-product-layout',
  templateUrl: './product-layout.component.html',
  styleUrls: ['./product-layout.component.scss']
})
export class ProductLayoutComponent implements OnInit {
  isShow: boolean;
  topPosToStartShowing = 100;
  constructor(private router: Router, private storageService: StorageService) {}
  ngOnInit() {
    // this.router.navigateByUrl('/app/dashboard');
  }
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }
  // TODO: Cross browsing
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
  logOut(): void{
    this.storageService.clear();
    window.location.href = '/';
  }
}
