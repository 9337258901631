<section id="page-top">
    <!-- Page Wrapper -->
    <div id="wrapper">
       <app-product-sidebar></app-product-sidebar>
       <!-- Content Wrapper -->
       <div id="content-wrapper" class="d-flex flex-column">
          <!-- Main Content -->
          <div id="content">
             <app-product-header></app-product-header>
             <router-outlet></router-outlet>
          </div>
          <!-- End of Main Content -->
          <app-product-footer></app-product-footer>
       </div>
       <!-- End of Content Wrapper -->
    </div>
    <!-- End of Page Wrapper -->
    <!-- Scroll to Top Button-->
    <a class="scroll-to-top rounded" (click)="gotoTop()" [ngClass]="isShow ? 'scroll-active' : 'scroll-inactive'">
    <i class="fa fa-angle-up"></i>
    </a>
    <!-- Logout Modal-->
    <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
       <div class="modal-dialog" role="document">
          <div class="modal-content">
             <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
             </div>
             <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
             <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                <a class="btn btn-primary" href="javascript:void(0)" (click)="logOut()">Logout</a>
             </div>
          </div>
       </div>
    </div>
 </section>